PageApp.Views.ItemVideosView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemVideos,
  serializeData: function () {
    var numbVideos = _.size(this.model.get('videos'))
    var firstVideo
    if (numbVideos > 0) {
      firstVideo = this.model.get('videos')[0]
    }
    var videosActive = null
    if (numbVideos > 0) {
      videosActive = 'active'
    }

    var content = {
      protocol: myApp.ent.config.get('protocol'),
      title: this.model.get('title'),
      hasVideos: (numbVideos > 0),
      videosActive: videosActive,
      firstVideo: firstVideo,
      videos: this.model.get('videos')
    }
    return content
  }
})
