PageApp.Views.CardVerifyBillingAddressDetailsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.billingAddress,
  ui: {
    addressLine1: '#billingAddressLine1',
    addressLine2: '#billingAddressLine2',
    city: '#billingAddressCity',
    countyOrState: '#billingAddressCountyOrState',
    postcode: '#billingAddressPostcode',
    country: '#billingAddressCountry'

  },
  serializeData: function () {
    var content = {
      JspPublicCodes_JSP_CARD_VERIFICATION_ADDRESS_LINE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_ADDRESS_LINE'),
      JspPublicCodes_JSP_CARD_VERIFICATION_ADDRESS_LINE2: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_ADDRESS_LINE2'),
      JspPublicCodes_JSP_CARD_VERIFICATION_CITY: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_CITY'),
      JspPublicCodes_JSP_CARD_VERIFICATION_STATE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_STATE'),
      JspPublicCodes_JSP_CARD_VERIFICATION_POSTAL_CODE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_POSTAL_CODE'),
      JspPublicCodes_JSP_CARD_VERIFICATION_COUNTRY: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_COUNTRY'),
      JspPublicCodes_JSP_LABEL_SELECT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LABEL_SELECT'),
      countries: this.model.countries
    }
    return content
  },

  getUpdatedModel: function () {
    this.updateModelWithInput()
    return this.model
  },

  updateModelWithInput: function () {
    this.model.addressLine1 = this.ui.addressLine1.val()
    this.model.addressLine2 = this.ui.addressLine2.val().trim()
    this.model.city = this.ui.city.val().trim()
    this.model.countyOrState = this.ui.countyOrState.val().trim()
    this.model.postcode = this.ui.postcode.val().trim()
    this.model.country = this.ui.country.val().trim()
  }

})
