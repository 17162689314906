PageApp.Views.ItemDocumentsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemDocuments,
  serializeData: function () {
    var numbDocs = _.size(this.model.get('documents'))
    var numbAuctionDocs = _.size(this.model.get('auction').documents)
    if ((numbDocs + numbAuctionDocs) > 0) {
      var docsActive = 'active'
    }

    var content = {
      JspPublicCodes_JSP_ITEM_DOCUMENTS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_DOCUMENTS'),
      JspPublicCodes_JSP_AUCTION_DOCUMENTS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_DOCUMENTS'),
      title: this.model.get('title'),
      hasDocuments: (numbDocs + numbAuctionDocs) > 0,
      hasItemDocuments: (numbDocs > 0),
      hasAuctionDocuments: (numbAuctionDocs > 0),
      documents: this.model.get('documents'),
      aDocuments: this.model.get('auction').documents,
      lotNumber: this.model.get('lotNumber'),
      docsActive: docsActive
    }
    return content
  }
})
