PageApp.Views.ItemAlertsView = Backbone.Marionette.CompositeView.extend({
  template: hbs.templates.itemAlertsView,
  childView: PageApp.Views.ItemAlertView,
  childViewContainer: '#tbody-region',
  serializeData: function () {
    var contextPath = myApp.ent.config.get('contextPath')
    var content = {
      contextPath: contextPath,
      JspPublicCodes_JSP_ITEM_ALERTS_ALERT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_ALERT'),
      JspPublicCodes_JSP_ITEM_ALERTS_STATUS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_STATUS'),
      JspPublicCodes_JSP_ITEM_ALERTS_ACTIONS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_ACTIONS'),
      JspPublicCodes_JSP_ITEM_ALERTS_RESULTS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_RESULTS'),
      JspPublicCodes_JSP_ITEM_ALERTS_VIEW: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_VIEW'),
      JspPublicCodes_JSP_ITEM_ALERTS_DELETE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_DELETE')
    }
    return content
  }
})
