PageApp.Views.CardVerifyLayout = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.cardVerificationLayout,
  regions: {
    creditCardDetailsRegion: '#creditCardDetailsRegion',
    billingAddressDetailsRegion: '#billingAddressDetailsRegion'
  },
  events: {
    'click .x-verify': 'handleSubmit',
    'click .x-skip': 'handleSkip',
    'keyup :text': 'handleSubmitFromInput',
    'change .form-control': 'clearError'
  },
  serializeData: function () {
    return this.model.get('layout')
  },
  onRender: function () {
    this.creditCardDetailsRegion.show(new PageApp.Views.CardVerifyCardDetailsView({ model: this.model.attributes.creditCardDetails }))
    this.billingAddressDetailsRegion.show(new PageApp.Views.CardVerifyBillingAddressDetailsView({ model: this.model.attributes.billingAddress }))
  },
  clearError: function (e) {
    var $targetField = $(e.currentTarget)
    myApp.utils.validation.clearFieldError($targetField)
  },
  getUpdatedModel: function () {
    this.updateModelWithInput()
    return this.model
  },
  updateModelWithInput: function () {
    this.model.set('creditCardDetails', this.creditCardDetailsRegion.currentView.getUpdatedModel())
    this.model.set('billingAddress', this.billingAddressDetailsRegion.currentView.getUpdatedModel())
    this.model.set('flow', 'Registration')
  },

  // form submition below
  handleSubmitFromInput: function (e) {
    e.preventDefault()
    if (e.keyCode === 13) {
      this.handleSubmit(e)
    }
    return false
  },
  handleSkip: function (e) {
    e.preventDefault()

    var url = myApp.utils.ajax.getApiEndpoint('cardVerificationSkipAjax')
    myApp.utils.ajax.postBean(null, url, _.bind(function (response) {
      if (response.models.ErrorModel) {
        myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
        var errors = new PageApp.Ent.Errors(response.models.ErrorModel.errors)
        myApp.utils.validation.renderErrors(errors)
        return
      }
      if (response.models.RegisterForAuctionSuccessModel) {
        myApp.vent.trigger('register:success', { response: response })
      }
    }, this))
  },

  handleSubmit: function (e) {
    e.preventDefault()
    this.updateModelWithInput()

    var url = myApp.utils.ajax.getApiEndpoint('cardVerificationAjax')
    myApp.utils.ajax.postBean(this.model, url, _.bind(function (response) {
      if (response.models.ErrorsModel) {
        myApp.vent.trigger('ui:notification', { text: response.models.ErrorsModel.message, level: 'warning' })
        var errors = new PageApp.Ent.Errors(response.models.ErrorsModel.errors)
        myApp.utils.validation.renderErrors(errors)
        return
      }
      if (response.models.RegisterForAuctionSuccessModel) {
        myApp.vent.trigger('register:success', { response: response })
      }
    }, this))

    return false
  }

})
