PageApp.Views.TimedItemActions = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.timedItemActions
  },
  events: {
    'click .x-login': 'handleLogin',
    'click .x-register': 'handleRegister',
    'click .x-pending': 'handlePending',
    'click .x-pay-deposit': 'handlePayDeposit',
    'click .x-place-bid': 'handleConfirmPlaceBid',
    'click .x-cancel': 'handleCancelConfirmPlaceBid',
    'click .x-makeoffer': 'handleOpenMakeOffer',
    'click .x-buynow': 'handleOpenBuyNow',
    'click .x-transfer': 'handleOpenTransfer',
    'click .x-tender': 'handleOpenTender',
    'click .x-tenderpct': 'handleOpenTenderPct',
    'click .x-auto-bid-cancel': 'handleAutoBidCancel',
    'click .x-watching': 'handleWatching',
    'click .x-contact-seller': 'handleContactSeller'
  },
  serializeData: function () {
    var buttonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_LOG_IN')
    var buttonHelpText = ''
    var actionId = 'login'
    var action = 'login'
    var showBidButton = true
    var showAutobidOnly = this.model.get('clientOptions').showAutobidOnly
    var supportsBidding = this.model.get('supportsBidding')
    var bidButtonDisabledProperty = ''
    var showSimilarButton = false
    var shouldBiddingBeDisabled = false
    if (myApp.ent.user.isLoggedIn()) {
      if (this.model.attributes.registrant.status === 5 || this.model.attributes.registrant.id < 0) {
        buttonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_REGISTER')
        actionId = 'register'
        action = 'register'
      } else if (this.model.attributes.registrant.status === 1) { // Pending
        buttonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_PLACE_BID')
        actionId = 'placeBid'
        action = 'place-bid'
        bidButtonDisabledProperty = 'disabled'
        showBidButton = supportsBidding && !showAutobidOnly
      } else if (this.model.attributes.registrant.status === 4 || this.model.attributes.registrant.status === 7) {
        buttonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_PAY_DEPOSIT')
        actionId = 'payDeposit'
        action = 'pay-deposit'
      } else { // user is registered in this auction
        if (showAutobidOnly) {
          showBidButton = false
        } else {
          if (supportsBidding && this.model.get('statusId') === 4) {
            var breakLine = ' '
            if (this.options.placing) {
              buttonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_CONFIRM') + breakLine + this.model.get('amountNextBidFormattedWithCurrency')
              actionId = 'confirmBid'
              action = 'confirm-bid'
            } else {
              this.options.placing = false
              buttonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_PLACE_BID') + breakLine + this.model.get('amountNextBidFormattedWithCurrency')
              actionId = 'placeBid'
              action = 'place-bid'
            }
            if (this.model.get('showNumberOfUnits')) {
              buttonLabel += ' (x' + this.model.get('numberOfUnits') + ')'
            }
            if (this.model.attributes.registrant.status === 3 || this.model.attributes.registrant.status === 6) {
              bidButtonDisabledProperty = 'disabled'
            }
          } else {
            showBidButton = false
          }
        }
      }
      // if we're in a prebidding state for timed auctions, and the user is registered, disable any buttons
      if (this.model.attributes.shouldBiddingBeDisabled) {
        bidButtonDisabledProperty = 'disabled'
        shouldBiddingBeDisabled = true
        buttonHelpText = this.model.attributes.auctionNotStartedString
      }
      if (this.model.get('closed')) {
        showBidButton = false
        showSimilarButton = true
        buttonLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_VIEW_SIMILAR')
        action = 'similar'
      }
    }

    var showWatchCheckBox = true

    var content = {
      JspPublicCodes_JSP_BIDDING_CONTACT_SELLER: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_CONTACT_SELLER'),
      JspPublicCodes_JSP_BIDDING_CANCEL: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_CANCEL'),
      JspPublicCodes_JSP_BIDDING_CONFIRM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_CONFIRM'),
      JspPublicCodes_JSP_BIDDING_GOTO_WATCH_LIST: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_GOTO_WATCH_LIST'),
      JspPublicCodes_JSP_BIDDING_WATCH_ITEM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_WATCH_ITEM'),
      JspPublicCodes_JSP_BIDDING_WATCHING_ITEM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_WATCHING_ITEM'),
      placing: this.options.placing,
      buttonHelpText: buttonHelpText,
      buttonLabel: buttonLabel,
      action: action,
      actionId: actionId,
      shouldShowActions: !this.model.attributes.isWebcast || this.model.attributes.isPreBiddingEnabled || this.model.attributes.timeUntilStart < 0 || action === 'register' || action === 'login',
      showBidButton: showBidButton,
      showGotoWatchListLink: (myApp.ent.config.get('page') !== 'myBidsLand'),
      showSimilarButton: showSimilarButton,
      showWatchCheckBox: showWatchCheckBox,
      bidButtonDisabledProperty: bidButtonDisabledProperty,
      shouldBiddingBeDisabled: shouldBiddingBeDisabled,
      placeHolderMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_ENTER_AUTOBID'),
      errorMessage: (this.model.get('error')) ? this.addErrorMessage() : ''
    }
    content = _.extend(content, this.model.attributes)
    return content
  },
  onRender: function () {
    // (re)attach the tooltip event handlers
    this.$el.tooltip({ selector: '[data-toggle="tooltip"]' })
  },
  handleWatching: function (e) {
    e.preventDefault()
    myApp.utils.ajax.postBean(null, myApp.utils.ajax.getApiEndpoint('timed/watching') + '/' + this.model.get('id') + '/' + myApp.ent.user.get('id'), _.bind(function (response) {
      if (response.models.ErrorModel) {
        myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
      } else {
        var item = myApp.request('reqres:timed:fetch:item', { itemId: response.models.WatchLotModel.lot_id })
        item.set('watching', response.models.WatchLotModel.watching) // causes a re-render
      }
    }, this))
  },
  handleContactSeller: function () {
    var model = {
      id: this.model.get('id'),
      title: this.model.get('title')
    }
    myApp.vent.trigger('contactseller:display', { model: model })
  },
  addErrorMessage: function () {
    var keyPrefix = 'JspPublicCodes_JSP_BIDDING_AUTOBID_'
    if (this.model.get('inLiveBidding')) {
      keyPrefix = 'JspPublicCodes_JSP_BIDDING_MAX_BID_'
    }
    var errorMessage = myApp.reqres.request('i16:getString', keyPrefix + 'CONFIRM_1')
    errorMessage += ' ' + myApp.ent.config.get('confirmAmountThresholdMultiplier') + ' '
    if (this.model.get('winningOrWon')) {
      errorMessage += myApp.reqres.request('i16:getString', keyPrefix + 'CONFIRM_2')
    } else {
      errorMessage += myApp.reqres.request('i16:getString', keyPrefix + 'CONFIRM_2A')
    }
    errorMessage += ' ' + this.model.get('currencySymbol') + this.model.attributes.autoBidValue + ' '
    errorMessage += myApp.reqres.request('i16:getString', keyPrefix + 'CONFIRM_3')
    return errorMessage
  },
  handleAutoBidCancel: function (e) {
    e.preventDefault()
    this.$el.find('.x-auto-bid-input').val('')
    this.model.set('error', false)
    this.model.set('autoBidValue', undefined)
    this.model.set('reconfirmed', undefined)
    this.render()
    return false
  },
  handleLogin: function (e) {
    e.preventDefault()
    myApp.router.navigate('#!/login', { trigger: true })
  },
  handlePending: function (e) {
    e.preventDefault()
  },
  handleRegister: function (e) {
    e.preventDefault()
    var url = myApp.ent.config.get('contextPath') + '/registerForAuction/' + this.model.get('auctionId')
    if (!myApp.ent.user.isLoggedIn()) {
      return myApp.router.navigate('#!/login', { trigger: true })
    }
    window.location.href = url
  },
  handlePayDeposit: function (e) {
    e.preventDefault()
    window.location.href = myApp.ent.config.get('contextPath') + '/payDeposit?registrantId=' + this.model.get('registrant').id
  },
  handleConfirmPlaceBid: function (e) {
    e.preventDefault()
    if (this.model.get('closed')) {
      myApp.vent.trigger('ui:notification', { text: 'This item has expired', level: 'warning' })
      myApp.vent.trigger('timed:display')
    } else if (!myApp.ent.user.isLoggedIn()) {
      myApp.vent.trigger('ui:notification', { text: 'You are not logged in.', level: 'warning' })
    } else if (!this.model.attributes.registrant.approved) {
      myApp.vent.trigger('ui:notification', { text: 'Your registration is not approved.', level: 'warning' })
    } else {
      this.options.placing = true
      this.render()
    }
  },
  handleCancelConfirmPlaceBid: function (e) {
    e.preventDefault()
    this.options.placing = false
    this.render()
  },
  handleOpenBuyNow: function (e) {
    e.preventDefault()
    var item = myApp.request('reqres:timed:fetch:item', { itemId: this.model.attributes.id })
    var model = myApp.ent.purchaseEntity.setModelForBuyNow(item.attributes, 1)
    this.fireTrigger(model, 'buynow:display')
    return false
  },
  handleOpenMakeOffer: function (e) {
    e.preventDefault()
    var item = myApp.request('reqres:timed:fetch:item', { itemId: this.model.attributes.id })
    var model = myApp.ent.purchaseEntity.setModelForMakeOffer(item.attributes, false)
    this.fireTrigger(model, 'makeoffer:display')
    return false
  },
  handleOpenTransfer: function (e) {
    e.preventDefault()
    var item = myApp.request('reqres:timed:fetch:item', { itemId: this.model.attributes.id })
    var model = myApp.ent.purchaseEntity.setModelForTransfer(item.attributes, 1)
    this.fireTrigger(model, 'transfer:display')
    return false
  },
  handleOpenTender: function (e) {
    e.preventDefault()
    var item = myApp.request('reqres:timed:fetch:item', { itemId: this.model.attributes.id })
    var model = myApp.ent.purchaseEntity.setModelForTender(item.attributes, true)
    this.fireTrigger(model, 'tender:display')
    return false
  },
  handleOpenTenderPct: function (e) {
    e.preventDefault()
    var item = myApp.request('reqres:timed:fetch:item', { itemId: this.model.attributes.id })
    var model = myApp.ent.purchaseEntity.setModelForTender(item.attributes, true)
    this.fireTrigger(model, 'tenderPct:display')
    return false
  },
  fireTrigger: function (model, triggerName) {
    if (model.get('closed') && !(triggerName === 'tender:display' || triggerName === 'tenderPct:display')) {
      myApp.vent.trigger('ui:notification', { text: 'This item has expired', level: 'warning' })
      myApp.vent.trigger('timed:display')
    } else if (!myApp.ent.user.isLoggedIn()) {
      myApp.vent.trigger('ui:notification', { text: 'You are not logged in.', level: 'warning' })
    } else if (!this.model.attributes.registrant.approved) {
      myApp.vent.trigger('ui:notification', { text: 'Your registration is not approved.', level: 'warning' })
    } else {
      myApp.vent.trigger(triggerName, { model: model })
    }
  }
})
