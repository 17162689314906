PageApp.Controllers.ItemlandRouter = function (config) {
  function initCommands () {
  }
  function initEvents () {
    myApp.vent.on('layoutcontrol:display', function () {
      var page = myApp.ent.config.get('page')
      if (page === 'itemland') {
        myApp.vent.trigger('itemland:display')
      }
    }, this)
    myApp.vent.on('itemland:display', function (singleItemModel) {
      if (singleItemModel) {
        this.myModel = singleItemModel
      } else {
        // no page reload
        singleItemModel = this.myModel
        singleItemModel.timedModels = undefined
      }
      var singleItem = new PageApp.Ent.BasicEntity(singleItemModel)
      myApp.mainRegion.show(new PageApp.Views.ItemlandLayout({ model: singleItem }))
      if (singleItemModel.auction && !singleItemModel.auction.catalogue && singleItemModel.auction.webcastAuction && singleItemModel.auction.statusId === 4) {
        myApp.vent.trigger('landing:webcast:check', {
          webcastStartTime: singleItemModel.auction.webcastStartTime,
          auctionId: singleItemModel.auction.id
        })
      }
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
      initCommands()
    }

  }
}
