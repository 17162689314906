PageApp.Controllers.ApplicationRouter = function (config) {
  function checkForError (response) {
    var failed = false
    if (response && response.failed) {
      if (response.models && response.models.ErrorModel) {
        if (response.models.ErrorModel.code === 'reload') {
          window.location.reload()
        }
        myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
      } else {
        myApp.vent.trigger('ui:notification', { text: 'error - please reload the page', level: 'warning' })
      }
      failed = true
    } else if (response.models && response.models.ErrorModel) {
      myApp.vent.trigger('ui:notification', { text: response.models.ErrorModel.message, level: 'warning' })
      failed = true
    }
    return failed
  }

  function redirectToHome () {
    myApp.router.navigate('#!/home', { trigger: true })
  }

  function redirectToAccount () {
    window.location.replace(window.location.href.replace(/mySettings|createAccount/, 'account'))
  }

  function hasMissingUserDetails () {
    return (myApp.ent.user.isLoggedIn() && hasOAuthEnabled() && myApp.ent.user.get('statusId') === 7)
  }

  function hasOAuthEnabled () {
    return (!!window.bidjs.options.OAuth)
  }

  function setSecurePath (model, pageKey) {
    var securePath = '#!/notFound'
    if (model && model.secureQuery) {
      securePath = '#!/' + model.secureQuery
      if (model.secureQuery.indexOf('auctionDetails') > -1) {
        securePath += '/' + model.auction.id
      } else if (model.secureQuery.indexOf('itemDetails') > -1) {
        securePath += '/' + model.id
      }
    }
    myApp.ent.config.set({ 'page': pageKey, 'secureQuery': securePath })
  }

  function setPage (isTitleOverridable, isMetaDescriptionOverridable, metaModel) {
    if (isTitleOverridable) {
      var titleElement = document.getElementsByTagName('title')[0]
      if (!titleElement) {
        titleElement = document.createElement('title')
        document.head.appendChild(titleElement)
      }
      titleElement.innerHTML = (metaModel && metaModel.metaTitle) ? metaModel.metaTitle : myApp.ent.metaModel.get('metaTitle')
    }
    if (isMetaDescriptionOverridable) {
      var metaDescElement = document.querySelector('meta[name="description"]')
      if (metaDescElement) {
        metaDescElement.setAttribute('content', (metaModel && metaModel.metaDescription) ? metaModel.metaDescription : myApp.ent.metaModel.get('metaDescription'))
      }
    }
  }

  var setPageMeta
  function initRoutes (obj) {
    var context = '!/'
    var appRoutes = {}
    appRoutes[context + 'createAccount'] = 'createAccountRequest'
    appRoutes[context + 'mySettings'] = 'mySettingsRequest'
    appRoutes[context + 'confirmEmail'] = 'confirmEmailRequest'
    appRoutes[context + 'marketplace'] = 'marketplaceRequest'
    appRoutes[context + 'marketplaceSecure'] = 'marketplaceRequest'
    appRoutes[context + 'marketplace?:query'] = 'marketplaceRequest'
    appRoutes[context + 'marketplaceSecure?:query'] = 'marketplaceRequest'
    appRoutes[context + 'search'] = 'searchRequest'
    appRoutes[context + 'searchSecure'] = 'searchRequest'
    appRoutes[context + 'myBids(/:filterId)'] = 'myBidsRequest'
    appRoutes[context + 'auctionDetails/:auctionId'] = 'auctionDetailsRequest'
    appRoutes[context + 'auctionDetails/:auctionId?:query'] = 'auctionDetailsRequest'
    appRoutes[context + 'auctionDetailsSecure/:auctionId'] = 'auctionDetailsSecureRequest'
    appRoutes[context + 'auctionDetailsSecure/:auctionId?:query'] = 'auctionDetailsSecureRequest'
    appRoutes[context + 'itemDetails/:auctionId/:itemId'] = 'itemDetailsRequest'
    appRoutes[context + 'itemDetailsSecure/:auctionId/:itemId'] = 'itemDetailsSecureRequest'
    appRoutes[context + 'prevNextItemDetails/:itemId/:prevnext'] = 'prevNextItemDetailsRequest'
    appRoutes[context + 'registerForAuction/:prevnext'] = 'registerForAuctionRequest'

    appRoutes['*notFound'] = 'nullRequest'
    obj.router = new Marionette.AppRouter({
      controller: obj,
      appRoutes: appRoutes
    })
    obj.router.onRoute = function (name, path, args) {
      myApp.utils.misc.resetScrollPosition()
      if (name.indexOf('registerForAuction') === -1 && name.indexOf('login') === -1 && name.indexOf('logout') === -1) {
        myApp.ent.config.set('referer', '#' + Backbone.history.fragment)
      }
      myApp.vent.trigger('lightbox:close')
      // prettyPhoto doesn't provide a method for us to see if it's open, so we need to try / catch it, as it throws an error if you try to close it without it being open
      if ($.prettyPhoto && $.prettyPhoto.close) try { $.prettyPhoto.close() } catch (e) {}

      if (name === 'nullRequest') return

      let argReplacementCount = 0
      const replacePathVarWithArg = function () {
        const arg = args[argReplacementCount]
        argReplacementCount++
        if (arg) {
          return arg
        }
        return ''
      }

      // manually track page on navigation
      const pathVarReplacementRegex = /(?=:).*?(?=(\/|\?|$))/sg
      const pathHashBangReplacementRegex = /(!|#!)/s
      const currentPathWithVars = path
        .replace(pathVarReplacementRegex, replacePathVarWithArg)
        .replace(pathHashBangReplacementRegex, '')

      const gaName = window.GoogleAnalyticsObject

      window[gaName]('bidjs.set', 'page', currentPathWithVars)
      window[gaName]('bidjs.set', 'hostname', window.location.hostname)
      window[gaName]('bidjs.send', 'pageview')

      if (myApp.ent.config && myApp.ent.config.attributes.options && myApp.ent.config.attributes.options.analyticsCode) {
        const additionalAnalyticsNamespace = (myApp.ent.config.attributes.options.analyticsNamespace) ? `${myApp.ent.config.attributes.options.analyticsNamespace}.` : ''
        window[gaName](`${additionalAnalyticsNamespace}set`, 'page', currentPathWithVars)
        window[gaName](`${additionalAnalyticsNamespace}set`, 'hostname', window.location.hostname)
        window[gaName](`${additionalAnalyticsNamespace}send`, 'pageview')
      }

      if (window.bidjs.callback) {
        window.bidjs.callback({
          action: 'BIDJS_NAVIGATED',
          data: {
            path: currentPathWithVars
          }
        })
      }
    }
  }

  return {
    initialize: function () {
      initRoutes(this)
      setPageMeta = setPage.bind(this,
        (
          myApp.ent.config &&
          myApp.ent.config.attributes.options &&
          myApp.ent.config.attributes.options.allowTitleChange
        ), (
          myApp.ent.config &&
          myApp.ent.config.attributes.options &&
          myApp.ent.config.attributes.options.allowMetaDescriptionChange
        )
      )
    },
    onRoute: function () {
      $('#myModal').hide()
    },
    doRoute: function (event, router) {
      if (!event || !event.href) {
        // only allow calls to this in bidjs from the search filter code
        return
      }
      myApp.router.navigate(event.href, { trigger: true })
    },

    nullRequest: function () {
      myApp.mainRegion.show(new PageApp.Views.NullView())
      myApp.myModalRegion.empty()
      setPageMeta()
    },

    notFoundRequest: function (path) {
      if (hasMissingUserDetails()) {
        return redirectToAccount()
      }
      myApp.mainRegion.show(new PageApp.Views.ErrorNotFoundView({ model: myApp.ent.fourOFour }))
      setPageMeta()
    },

    createAccountRequest: function () {
      return redirectToAccount()
    },
    mySettingsRequest: function () {
      redirectToAccount()
    },
    confirmEmailRequest: function () {
      if (hasOAuthEnabled()) {
        return redirectToHome()
      }
      window.location.replace(window.location.href.replace('confirmEmail', 'accountActivate'))
    },
    content: function (page) {
      if (hasMissingUserDetails()) {
        return redirectToAccount()
      }
      myApp.myModalRegion.show(new PageApp.Views.WaitingView())
      myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('content/plain') + '/' + page, _.bind(function (response) {
        if (checkForError(response)) return
        setPageMeta((response && response.models && response.models.MetaModel))
        myApp.ent.config.set('page', 'content')
        myApp.vent.trigger('content:display', response)
        myApp.myModalRegion.empty()
      }, this))
    },
    auctionDetailsSecureRequest: function (auctionId, query) {
      if (myApp.ent.user.isLoggedIn()) {
        if (hasMissingUserDetails()) {
          return redirectToAccount()
        }
        return this.auctionDetailsRequest(auctionId, query)
      }
    },
    auctionDetailsRequest: function (auctionId, query) {
      if (hasMissingUserDetails()) {
        return redirectToAccount()
      }
      // note from Ralph: I'm not sure how this is working, as biddingRegion is not defined
      if (typeof biddingRegion !== 'undefined' && biddingRegion) { // eslint-disable-line no-undef
        new PageApp.Views.PaginationWaitingView({ el: biddingRegion }).render() // eslint-disable-line no-undef
      } else {
        myApp.myModalRegion.show(new PageApp.Views.WaitingView())
      }
      var url = myApp.utils.ajax.getApiEndpoint('auctionDetails') + '/' + auctionId + '?' + query
      myApp.utils.ajax.get(null, url, _.bind(function (response) {
        if (checkForError(response)) {
          myApp.mainRegion.show(new PageApp.Views.ErrorNotFoundView({ model: myApp.ent.fourOFour }))
          myApp.myModalRegion.empty()
          return
        }
        if (response.models.LandingModel.auction.redirectToWebcast) {
          myApp.router.navigate('#!/webcast/' + auctionId, { trigger: true, replace: true })
          return
        }
        setPageMeta((response && response.models && response.models.MetaModel))
        myApp.ent.config.set({ 'page': 'auctionland', 'secureQuery': '#!/' + response.models.LandingModel.secureQuery })
        if (checkForError(response)) return
        setSecurePath(response.models.LandingModel, 'auctionland')
        var config = { landingModel: response.models.LandingModel }
        myApp.vent.trigger('landing:display', config)
        myApp.myModalRegion.empty()
      }, this))
    },
    searchRequest: function (query) {
      if (hasMissingUserDetails()) {
        return redirectToAccount()
      }
      myApp.myModalRegion.show(new PageApp.Views.WaitingView())
      myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('search') + '?' + query, _.bind(function (response) {
        if (checkForError(response)) return
        setPageMeta((response && response.models && response.models.MetaModel))
        setSecurePath(response.models.LandingModel, 'searchland')
        myApp.vent.trigger('landing:display', { landingModel: response.models.LandingModel })
        myApp.myModalRegion.empty()
      }, this))
    },
    marketplaceRequest: function (query) {
      if (hasMissingUserDetails()) {
        return redirectToAccount()
      }
      myApp.myModalRegion.show(new PageApp.Views.WaitingView())
      myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('marketplace') + '?' + query, _.bind(function (response) {
        if (checkForError(response)) return
        setPageMeta((response && response.models && response.models.MetaModel))
        setSecurePath(response.models.LandingModel, 'marketland')
        myApp.vent.trigger('landing:display', { landingModel: response.models.LandingModel })
        myApp.myModalRegion.empty()
      }, this))
    },
    itemDetailsSecureRequest: function (auctionId, itemId) {
      if (myApp.ent.user.isLoggedIn()) {
        if (hasMissingUserDetails()) {
          return redirectToAccount()
        }
        return this.itemDetailsRequest(itemId)
      }
    },
    itemDetailsRequest: function (auctionId, itemId) {
      if (hasMissingUserDetails()) {
        return redirectToAccount()
      }
      myApp.myModalRegion.show(new PageApp.Views.WaitingView())
      myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('itemDetails') + '/' + itemId, _.bind(function (response) {
        if (checkForError(response)) return
        setPageMeta((response && response.models && response.models.MetaModel))
        setSecurePath(response.models.SingleItemModel, 'itemland')
        myApp.vent.trigger('itemland:display', response.models.SingleItemModel)
        myApp.myModalRegion.empty()
      }, this))
    },
    prevNextItemDetailsRequest: function (itemId, prevnext) {
      if (hasMissingUserDetails()) {
        return redirectToAccount()
      }
      myApp.myModalRegion.show(new PageApp.Views.WaitingView())
      myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('prevNextItemDetails') + '/' + itemId + '/' + prevnext, _.bind(function (response) {
        if (checkForError(response)) return
        setPageMeta((response && response.models && response.models.MetaModel))
        setSecurePath(response.models.SingleItemModel, 'itemland')
        myApp.ent.config.set({ 'page': 'itemland', 'secureQuery': '#!/' + response.models.SingleItemModel.secureQuery })
        myApp.vent.trigger('itemland:display', response.models.SingleItemModel)
        myApp.myModalRegion.empty()
      }, this))
    },
    myBidsRequest: function (filterId, query) {
      if (hasMissingUserDetails()) {
        return redirectToAccount()
      }
      if (typeof biddingRegion !== 'undefined' && biddingRegion) { // eslint-disable-line no-undef
        new PageApp.Views.PaginationWaitingView({ el: biddingRegion }).render() // eslint-disable-line no-undef
      } else {
        myApp.myModalRegion.show(new PageApp.Views.WaitingView())
      }
      if (myApp.ent.user.isLoggedIn()) {
        var url = myApp.utils.ajax.getApiEndpoint('myBids') + '/'
        if (filterId) url += filterId
        if (query) {
          url = url + '?' + query
        }
        myApp.utils.ajax.get(null, url, _.bind(function (response) {
          if (checkForError(response)) return
          setPageMeta((response && response.models && response.models.MetaModel))
          myApp.ent.config.set({ 'page': 'myBidsLand', 'secureQuery': '#!/' + response.models.MyBidsModel.secureQuery })
          myApp.vent.trigger('mybids:display', response.models.MyBidsModel)
        }, this))
      } else {
        myApp.mainRegion.empty()
        window.bidjsHooks.redirect('login')
      }
      myApp.myModalRegion.empty()
    },
    registerForAuctionRequest: function (auctionId) {
      if (hasMissingUserDetails()) {
        return redirectToAccount()
      }
      myApp.myModalRegion.show(new PageApp.Views.WaitingView())
      if (!myApp.ent.user.isLoggedIn()) {
        window.bidjsHooks.redirect('login')
        myApp.myModalRegion.empty()
      } else {
        myApp.myModalRegion.empty()
        window.location.replace(`${window.location.href.replace('registerForAuction', 'auctions')}/register`)
      }
    }

  }
}
