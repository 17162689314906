PageApp.Views.ConfirmDeleteView = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.confirmOkCancel,
  initialize: function (options) {
    this.model = options.model
    this.id = options.id
  },
  serializeData: function () {
    return {
      confirmMsg: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_CONFIRM_DELETE'),
      okButtonText: myApp.reqres.request('i16:getString', 'JSTextCodes_DELETE'),
      JSTextCodes_CANCEL: myApp.reqres.request('i16:getString', 'JSTextCodes_CANCEL')
    }
  },
  events: {
    'click .x-ok': 'handleDelete'
  },
  handleDelete: function (e) {
    var itemAlert = this.model.get(this.id)
    itemAlert.destroy({ success: this.handleSuccess, error: this.handleError })
    return true
  },
  handleError: function (model, xhr, options) {
    myApp.vent.trigger('error:notifyServerError', _.pick(xhr, 'responseJSON', 'status', 'statusText'))
  },
  handleSuccess: function (model) {
    var message = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_DELETED_ALERT') + ' ' + model.get('name')
    myApp.vent.trigger('ui:notification', { text: message, level: 'success' })
  }
})
