PageApp.Views.ItemDescriptionView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemDescription,
  serializeData: function () {
    var itemDescription = this.model.get('itemDescription')
    if (!itemDescription || itemDescription.length === 0) {
      itemDescription = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_NO_ITEM_DESCRIPTION')
    }
    var content = {
      itemDescription: itemDescription
    }
    return content
  }
})
