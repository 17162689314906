PageApp.Views.ItemAlertsLayout = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.itemAlertsLayout,
  events: {
    'click .x-create': 'showCreateDialog',
    'click .x-pause': 'showPauseDialog',
    'click .x-edit': 'showEditDialog',
    'click .x-delete': 'showDeleteDialog'
  },
  regions: {
    headerRegion: '#headerRegion',
    actionsRegion: '#actionsRegion',
    alertsRegion: '#alertsRegion'
  },
  onRender: function () {
    this.getRegion('headerRegion').show(new PageApp.Views.ItemAlertsHeaderView())
    this.getRegion('actionsRegion').show(new PageApp.Views.ItemAlertsActionsView())
    this.getRegion('alertsRegion').show(new PageApp.Views.ItemAlertsView({ collection: this.model }))
  },
  showDeleteDialog: function (e) {
    e.preventDefault()
    var id = $(e.currentTarget).data('id')
    myApp.vent.trigger('itemalerts:showdelete', { id: id, model: this.model })
  },
  showPauseDialog: function (e) {
    e.preventDefault()
    var id = $(e.currentTarget).data('id')
    myApp.vent.trigger('itemalerts:showpause', { id: id, model: this.model })
  },
  showCreateDialog: function (e) {
    e.preventDefault()
    myApp.vent.trigger('itemalerts:showcreate', { collection: this.model, model: new PageApp.Ent.ItemAlert() })
  },
  showEditDialog: function (e) {
    e.preventDefault()
    var id = $(e.currentTarget).data('id')
    myApp.vent.trigger('itemalerts:showcreate', { collection: this.model, model: this.model.get(id) })
  }
})
