PageApp.Views.CreateItemAlertView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.createItemAlert,
  initialize: function (options) {
    this.collection = options.collection
    this.model = options.model

    this.model.on('invalid', _.bind(this.showErrors, this))
  },
  events: {
    'click .x-submit': 'handleSubmit',
    'blur  .x-query': 'upperCaseBooleanOperators'
  },
  ui: {
    alertName: '.x-alert-name',
    query: '.x-query',
    sunday: '.x-sunday',
    monday: '.x-monday',
    tuesday: '.x-tuesday',
    wednesday: '.x-wednesday',
    thursday: '.x-thursday',
    friday: '.x-friday',
    saturday: '.x-saturday',
    nameGroup: '.x-name-group',
    queryGroup: '.x-query-group',
    frequencyGroup: '.x-frequency-group',
    errors: '.x-errors'
  },
  serializeData: function () {
    var content = {
      title: this.model.get('id') ? myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_EDIT_ALERT_TITLE') : myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_TITLE'),
      description: this.model.get('id') ? myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_EDIT_ALERT_DESC') : myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_DESC'),
      JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_NAME: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_NAME'),
      JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_SEARCH_CRIT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_SEARCH_CRIT'),
      JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_FREQUENCY: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_FREQUENCY'),
      JSTextCodes_SUNDAY: myApp.reqres.request('i16:getString', 'JSTextCodes_SUNDAY'),
      JSTextCodes_MONDAY: myApp.reqres.request('i16:getString', 'JSTextCodes_MONDAY'),
      JSTextCodes_TUESDAY: myApp.reqres.request('i16:getString', 'JSTextCodes_TUESDAY'),
      JSTextCodes_WEDNESDAY: myApp.reqres.request('i16:getString', 'JSTextCodes_WEDNESDAY'),
      JSTextCodes_THURSDAY: myApp.reqres.request('i16:getString', 'JSTextCodes_THURSDAY'),
      JSTextCodes_FRIDAY: myApp.reqres.request('i16:getString', 'JSTextCodes_FRIDAY'),
      JSTextCodes_SATURDAY: myApp.reqres.request('i16:getString', 'JSTextCodes_SATURDAY'),
      JSTextCodes_SAVE: myApp.reqres.request('i16:getString', 'JSTextCodes_SAVE'),
      JSTextCodes_CANCEL: myApp.reqres.request('i16:getString', 'JSTextCodes_CANCEL')
    }
    content = _.extend(content, this.model.attributes)
    return content
  },
  upperCaseBooleanOperators: function () {
    var str = this.ui.query.val()
    str = str.replace(/\band\b/ig, 'AND')
    str = str.replace(/\bor\b/ig, 'OR')
    str = str.replace(/\bnot\b/ig, 'NOT')
    this.ui.query.val(str)
  },
  handleSubmit: function (e) {
    this.hideErrors()

    this.model.set('name', this.ui.alertName.val())
    this.model.set('query', this.ui.query.val())
    if (!this.model.get('status')) {
      this.model.set('status', 'LIVE')
    }
    this.model.set('runOnSunday', this.ui.sunday.is(':checked'))
    this.model.set('runOnMonday', this.ui.monday.is(':checked'))
    this.model.set('runOnTuesday', this.ui.tuesday.is(':checked'))
    this.model.set('runOnWednesday', this.ui.wednesday.is(':checked'))
    this.model.set('runOnThursday', this.ui.thursday.is(':checked'))
    this.model.set('runOnFriday', this.ui.friday.is(':checked'))
    this.model.set('runOnSaturday', this.ui.saturday.is(':checked'))

    this.model.set('@class', 'com.bidlogix.scaffold.models.itemalerts.ItemAlertModel')

    this.collection.create(this.model, { async: false, success: _.bind(this.handleSuccess, this), error: _.bind(this.handleError, this), wait: true })

    if (this.hasErrors === true) {
      return false
    }
    return true
  },
  hideErrors: function () {
    this.ui.nameGroup.removeClass('has-error')
    this.ui.queryGroup.removeClass('has-error')
    this.ui.frequencyGroup.removeClass('has-error')
    this.ui.errors.text('')
    this.hasErrors = false
  },
  showErrors: function (model, errors) {
    _.each(errors, function (error) {
      if (error.name === 'name') {
        this.ui.nameGroup.addClass('has-error')
      } else if (error.name === 'query') {
        this.ui.queryGroup.addClass('has-error')
      } else if (error.name === 'frequency') {
        this.ui.frequencyGroup.addClass('has-error')
      }

      var msg = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_SAVE_ALERT_MISSING_FIELDS')
      this.ui.errors.text(msg)
    }, this)

    this.hasErrors = true
  },
  handleError: function (model, xhr, options) {
    var errorMsg = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_SAVE_ALERT_ERROR_MSG')

    if (xhr.responseJSON) {
      errorMsg = xhr.responseJSON.message
    }
    this.ui.errors.text(errorMsg)
    this.hasErrors = true
  },
  handleSuccess: function (model) {
    var message = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_SAVED_ALERT') + ' ' + model.get('name')
    myApp.vent.trigger('ui:notification', { text: message, level: 'success' })
  }
})
