PageApp.Ent.ItemAlert = Backbone.Model.extend({
  initialize: function (models, options) {
    this.urlRoot = myApp.ent.config.get('contextPath') + '/itemalerts'
  },
  validate: function (attrs) {
    var errors = []

    if (!attrs.name) {
      errors.push({ name: 'name' })
    }
    if (!attrs.query) {
      errors.push({ name: 'query' })
    }
    if (attrs.runOnSunday === false &&
      attrs.runOnMonday === false &&
      attrs.runOnTuesday === false &&
      attrs.runOnWednesday === false &&
      attrs.runOnThursday === false &&
      attrs.runOnFriday === false &&
      attrs.runOnSaturday === false) {
      errors.push({ name: 'frequency' })
    }

    if (errors.length > 0) return errors
  }
})
