PageApp.Views.ItemTabsView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.itemTabs
  },
  serializeData: function () {
    var descriptionActive, videosActive, imagesActive, makeOfferActive

    var hasDescription, hasVideos, hasImages, hasDocuments
    if (this.model.get('description') && this.model.get('description').length > 0) {
      hasDescription = true
    }
    if (this.model.get('videos') && _.size(this.model.get('videos')) > 0) {
      hasVideos = true
    }
    if (this.model.get('images') && _.size(this.model.get('images')) > 0) {
      hasImages = true
    }
    if (this.model.get('documents') && _.size(this.model.get('documents')) > 0) {
      hasDocuments = true
    }
    if (!hasDescription && hasImages) {
      imagesActive = 'active'
    } else {
      descriptionActive = 'active'
    }

    var content = {

      JspPublicCodes_JSP_AUCTION_INFORMATION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_INFORMATION'),
      JspPublicCodes_JSP_DESCRIPTION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_DESCRIPTION'),
      JspPublicCodes_JSP_VIDEOS_LABEL: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_VIDEOS_LABEL'),
      JspPublicCodes_JSP_PHOTOS_LABEL: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PHOTOS_LABEL'),
      JspPublicCodes_JSP_ADDITIONAL_DOCUMENTS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ADDITIONAL_DOCUMENTS'),
      JspPublicCodes_JSP_ITEM_DOCUMENTS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_DOCUMENTS'),
      JspPublicCodes_JSP_CONTACT_SELLER: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CONTACT_SELLER'),
      JspPublicCodes_JSP_MAKE_OFFER: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MAKE_OFFER'),
      descriptionActive: descriptionActive,
      videosActive: videosActive,
      imagesActive: imagesActive,
      makeOfferActive: makeOfferActive,
      hasVideos: hasVideos,
      hasImages: hasImages,
      hasDocuments: hasDocuments
    }
    return content
  }
})
