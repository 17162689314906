PageApp.Views.ItemlandLayout = Backbone.Marionette.LayoutView.extend({
  getTemplate: function () {
    return hbs.templates.itemlandLayout
  },
  regions: {
    breadcrumbRegion: '#breadcrumbRegion',
    tabsRegion: '#tabsRegion',
    tabsTargetRegion: '#tabsTargetRegion',
    auctioneerStripRegion: '#auctioneerStripRegion',
    relatedItemRegion: '#relatedItemRegion',
    biddingRegion: '#biddingRegion',
    saleInfoRegion: '#saleInfoRegion'
  },
  events: {
    'click .x-tab-change': 'handleTabChange',
    'click .x-saleinfo-modal': 'handleSaleInfoShow'
  },
  serializeData: function () {
    var isWebcastGroupItem = this.model.get('itemType').webcastGroupItem
    var content = {
      itemTitle: this.model.attributes.title,
      itemSummary: this.model.attributes.summary,
      isLoggedIn: myApp.ent.user.isLoggedIn(),
      numberOrRef: isWebcastGroupItem ? '' : myApp.ent.timedHelper.getNumberOrRef(this.model.attributes) + ': '
    }
    _.extend(content, this.model.attributes)

    return content
  },
  onRender: function () {
    this.showBreadcrumbs()
    this.showTabs()

    var hasImages = (this.model.get('images') && _.size(this.model.get('images')) > 0)
    var hasDescription = (this.model.get('description') && this.model.get('description').length > 0)

    if (!hasDescription && hasImages) {
      this.showImages()
    } else {
      this.showItemDescription()
    }

    this.showAuctioneerStrip()
    this.showRelatedItems()
    if (this.model.get('timedModels')) {
      myApp.vent.trigger('timed:display', { region: this.getRegion('biddingRegion'), model: this.model.get('timedModels') })
    } else {
      myApp.vent.trigger('timed:display', { region: this.getRegion('biddingRegion') })
    }
  },
  handleTabChange: function (e) {
    if (e) e.preventDefault()
    this.$el.find('.x-tab-change').each(function () {
      var $this = $(this)
      $this.parent().removeClass('active')
    })
    var $target = $(e.currentTarget)
    $target.parent().addClass('active')
    var toggle = $target.data('toggle')
    this.model.set('toggle', toggle)
    if (toggle === 'videos') {
      this.showVideos()
    } else if (toggle === 'images') {
      this.showImages()
    } else if (toggle === 'description') {
      this.showItemDescription()
    } else if (toggle === 'documents') {
      this.showDocuments()
    }
  },
  showContactSeller: function () {
    this.getRegion('tabsTargetRegion').show(new PageApp.Views.ItemImagesView({ model: this.model }))
  },
  showMakeOffer: function () {
    this.getRegion('tabsTargetRegion').show(new PageApp.Views.ItemImagesView({ model: this.model }))
  },
  showImages: function () {
    this.getRegion('tabsTargetRegion').show(new PageApp.Views.ItemImagesView({ model: this.model }))
  },
  showVideos: function () {
    this.getRegion('tabsTargetRegion').show(new PageApp.Views.ItemVideosView({ model: this.model }))
  },
  showDocuments: function () {
    this.getRegion('tabsTargetRegion').show(new PageApp.Views.ItemDocumentsView({ model: this.model }))
  },
  handleSaleInfoShow: function (e) {
    if (e) {
      e.preventDefault()
    }
    var saleInfo = new PageApp.Ent.BasicEntity(this.model.attributes.auction.saleInfo)
    saleInfo.set('source', 'landing')
    saleInfo.set('address', this.model.attributes.location)
    saleInfo.set('locationLatitude', this.model.attributes.locationLatitude)
    saleInfo.set('locationLongitude', this.model.attributes.locationLongitude)
    saleInfo.set('buyersPremium', this.model.attributes.buyersPremium)
    saleInfo.set('hammerTaxRate', this.model.attributes.hammerTaxRate)
    saleInfo.set('bpTaxRate', this.model.attributes.bpTaxRate)
    saleInfo.set('chargesTaxRate', this.model.attributes.chargesTaxRate)
    saleInfo.set('hideTextOrAsSpecified', true)
    myApp.vent.trigger('saleinfo:display', { saleInfoModel: saleInfo })
  },
  showItemDescription: function () {
    var itemDescription = new PageApp.Ent.BasicEntity()
    itemDescription.set('itemDescription', this.model.attributes.description)
    this.getRegion('tabsTargetRegion').show(new PageApp.Views.ItemDescriptionView({ model: itemDescription }))
  },
  showBreadcrumbs: function () {
    var itemBreadcrumb = new PageApp.Ent.BasicEntity()
    itemBreadcrumb.set('itemId', this.model.attributes.id)
    itemBreadcrumb.set('contextPath', myApp.ent.config.get('contextPath'))
    itemBreadcrumb.set('showGraphsForMySales', this.model.attributes.showGraphsForMySales)
    itemBreadcrumb.set('containsTranslatedContent', this.model.attributes.containsTranslatedContent) // true); // BD-2915 to style this in the ui...
    itemBreadcrumb.set('marketplace', this.model.attributes.marketplaceLot)
    itemBreadcrumb.set('auctionId', this.model.attributes.auction.id)
    itemBreadcrumb.set('continentIds', this.model.attributes.location.continentIds)
    this.getRegion('breadcrumbRegion').show(new PageApp.Views.ItemBreadcrumbView({ model: itemBreadcrumb }))
  },
  showAuctioneerStrip: function () {
    var auctioneer = new PageApp.Ent.BasicEntity(this.model.attributes.auction.auctioneer)
    if (myApp.ent.webApp.showAuctioneerStrip(myApp.ent.config.get('page'))) { // BD-2915 to style this set to true - or ue the contextPath myApp.ent.config.get('contextPath') === '/webcast-us' || myApp.ent.config.get('contextPath') === '/auction-014') {
      this.getRegion('auctioneerStripRegion').show(new PageApp.Views.AuctioneerStripView({ model: auctioneer }))
    }
  },
  showRelatedItems: function () {
    if (this.model.get('auction').auctioneer.clientOptions.hideRelatedItems) {
      return
    }
    myApp.utils.ajax.get(null, myApp.utils.ajax.getApiEndpoint('relatedItems') + '/' + this.model.get('id'), _.bind(function (response) {
      if (response) {
        for (var key in response) {
          var imageModel = response[key].imageModel
          if (imageModel) {
            imageModel.thumbSrc = myApp.utils.types.toCloudinaryImageSrc(imageModel, '96', '54', 'auto', 'fill', null)
          } else {
            response[key].imageModel = { thumbSrc: '' }
          }
        }
        var model = {
          relatedItems: response,
          itemId: this.model.get('id'),
          category: this.model.get('category'),
          contextPath: myApp.ent.config.get('contextPath')
        }
        var reg = this.getRegion('relatedItemRegion')
        reg.show(new PageApp.Views.ItemRelationsView({ model: new PageApp.Ent.BasicEntity(model) }))
      }
    }, this))
  },
  showTabs: function () {
    this.getRegion('tabsRegion').show(new PageApp.Views.ItemTabsView({ model: this.model }))
  }

})
