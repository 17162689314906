PageApp.Ent.WebApp = Backbone.Model.extend({
  showAuctioneerStrip: function (page) {
    if (this.get('auctioneerCentric')) {
      if (page === 'auctionland' || page === 'itemland') {
        return true
      }
    }
    return false
  }
})
