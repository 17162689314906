PageApp.Views.CardVerifyCardDetailsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.creditCard,
  ui: {
    nameOnCard: '#nameOnCard',
    cardNumber: '#cardNumber',
    cardExpirationMonth: '#cardExpirationMonth',
    cardExpirationYear: '#cardExpirationYear',
    securityCode: '#securityCode'
  },
  serializeData: function () {
    var content = {
      JspPublicCodes_JSP_CARD_VERIFICATION_NAME_ON_CARD: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_NAME_ON_CARD'),
      JspPublicCodes_JSP_CARD_VERIFICATION_CARD_NUMBER: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_CARD_NUMBER'),
      JspPublicCodes_JSP_CARD_VERIFICATION_EXPIRATION_DATE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_EXPIRATION_DATE'),
      JspPublicCodes_JSP_CARD_VERIFICATION_SECURITY_CODE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_SECURITY_CODE'),
      JspPublicCodes_JSP_CARD_VERIFICATION_CVV_INFO: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CARD_VERIFICATION_CVV_INFO'),
      JspPublicCodes_JSP_LABEL_SELECT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_LABEL_SELECT'),
      referenceExpirationMonths: this.model.referenceExpirationMonths,
      referenceExpirationYears: this.model.referenceExpirationYears
    }
    return content
  },

  getUpdatedModel: function () {
    this.updateModelWithInput()
    return this.model
  },

  updateModelWithInput: function () {
    this.model.nameOnCard = this.ui.nameOnCard.val()
    this.model.cardNumber = this.ui.cardNumber.val().trim()
    this.model.cardExpirationMonth = this.ui.cardExpirationMonth.val().trim()
    this.model.cardExpirationYear = this.ui.cardExpirationYear.val().trim()
    this.model.securityCode = this.ui.securityCode.val().trim()
  }
})
