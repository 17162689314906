PageApp.Controllers.CardVerifyRouter = function () {
  function initEvents () {
    myApp.vent.on('cardverify:display', function (config) {
      new PageApp.Views.WaitingView({ el: config.region }).render()
      var contentPromise = myApp.request('reqres:reference:fetch', { types: 'countries' })
      contentPromise.done(_.bind(function (result) {
        var referenceExpirationMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        var currentYear = new Date().getFullYear()
        var referenceExpirationYears = []
        for (var i = currentYear; i < currentYear + 20; i++) {
          referenceExpirationYears.push(i)
        }
        var cardModel = new PageApp.Ent.BasicEntity(config.model)
        cardModel.get('billingAddress').countries = result.countries
        cardModel.get('creditCardDetails').referenceExpirationMonths = referenceExpirationMonths
        cardModel.get('creditCardDetails').referenceExpirationYears = referenceExpirationYears
        cardModel.set('@class', 'com.bidlogix.scaffold.models.account.CreditCardVerificationModel')
        new PageApp.Views.CardVerifyLayout({ el: config.region, model: cardModel }).render()

        myApp.utils.validation.registerClearListener('nameOnCard')
        myApp.utils.validation.registerClearListener('cardNumber')
        myApp.utils.validation.registerClearListener('securityCode')
        myApp.utils.validation.registerClearListener('billingAddressLine')
        myApp.utils.validation.registerClearListener('billingAddressCity')
        myApp.utils.validation.registerClearListener('billingAddressZipCode')
        myApp.utils.validation.registerClearListener('billingAddressCountry')
        myApp.utils.validation.registerExpirationClearListener('cardExpirationMonth', 'cardExpirationYear')

        $('#submitButton').click(function () {
          var valid = true

          valid = myApp.utils.validation.fieldNotNull('nameOnCard') && valid
          valid = myApp.utils.validation.validateCardNumber() && valid
          valid = myApp.utils.validation.validateSecurityCode() && valid
          valid = myApp.utils.validation.ExpirationDateInFuture('cardExpirationMonth', 'cardExpirationYear') && valid
          valid = myApp.utils.validation.fieldNotNull('billingAddressLine') && valid
          valid = myApp.utils.validation.fieldNotNull('billingAddressCity') && valid
          valid = myApp.utils.validation.fieldNotNull('billingAddressZipCode') && valid
          valid = myApp.utils.validation.fieldNotNull('billingAddressCountry') && valid
          return valid
        })
      }), this)
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
