PageApp.Ent.CreditCardDetails = Backbone.Model.extend({
  defaults: {
    nameOnCard: '',
    cardNumber: '',
    cardExpirationMonth: '',
    cardExpirationYear: '',
    securityCode: ''
  },

  validate: function () {
    var errors = new PageApp.Ent.Errors()
    this.validateNameOnCard(errors)
    this.validateCardNumber(errors)
    this.validateSecurityCode(errors)
    this.validateExpirationDate(errors)
    if (errors.length > 0) {
      return errors
    }
  },
  validateNameOnCard: function (errors) {
    if (myApp.utils.validation.stringIsEmpty(this.get('nameOnCard'))) {
      errors.add({
        field: 'nameOnCard',
        code: 'JSTextCodes_FIELD_NOT_COMPLETE'
      })
    }
  },
  validateCardNumber: function (errors) {
    var cardNumber = this.get('cardNumber')
    if (myApp.utils.validation.stringIsEmpty(cardNumber)) {
      errors.add({
        field: 'cardNumber',
        code: 'JSTextCodes_FIELD_NOT_COMPLETE'
      })
    } else if (!(cardNumber.match(/^[0-9]{12,19}$/))) {
      errors.add({
        field: 'cardNumber',
        code: 'UserCodes_CARD_NUMBER_INVALID'
      })
    }
  },
  validateSecurityCode: function (errors) {
    if (myApp.utils.validation.stringIsEmpty(this.get('securityCode'))) {
      errors.add({
        field: 'securityCode',
        code: 'JSTextCodes_FIELD_NOT_COMPLETE'
      })
    } else if (!this.get('securityCode').match(/^[0-9]{3,4}$/)) {
      errors.add({
        field: 'securityCode',
        code: 'UserCodes_CARD_SECURITY_CODE_INVALID'
      })
    }
  },
  validateExpirationDate: function (errors) {
    var expirationMonth = this.get('cardExpirationMonth')
    var expirationYear = this.get('cardExpirationYear')
    var now = new Date()
    var currentYear = now.getFullYear()
    var currentMonth = now.getMonth() + 1
    if (expirationYear < currentYear || (currentYear === expirationYear && expirationMonth < currentMonth)) {
      errors.add({
        field: 'cardExpirationMonth',
        code: 'UserCodes_CARD_EXPIRATION_IN_PAST'
      })
    }
  }

})
