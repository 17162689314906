PageApp.Controllers.ItemAlertsRouter = function () {
  function initEvents () {
    myApp.vent.on('itemalerts:display', function (model) {
      myApp.mainRegion.show(new PageApp.Views.ItemAlertsLayout({ model: model }))
    }, this)
    myApp.vent.on('modal:closed', function (config) {
      this.modalInProgress = false
    }, this)
    myApp.vent.on('itemalerts:showdelete', function (config) {
      if (!this.modalInProgress) {
        this.modalInProgress = true
        var view = new PageApp.Views.ConfirmDeleteView(config)
        myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: view }))
      }
    }, this)
    myApp.vent.on('itemalerts:showpause', function (config) {
      if (!this.modalInProgress) {
        this.modalInProgress = true
        var view = new PageApp.Views.ConfirmPauseView(config)
        myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: view }))
      }
    }, this)
    myApp.vent.on('itemalerts:showcreate', function (config) {
      if (!this.modalInProgress) {
        this.modalInProgress = true
        var view = new PageApp.Views.CreateItemAlertView(config)
        myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: view }))
      }
    }, this)
  }
  return {
    initialize: function () {
      initEvents()
    }
  }
}
