PageApp.Views.ItemAlertView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemAlertView,
  tagName: 'tr',
  initialize: function () {
    this.listenTo(this.model, 'sync', this.render)
  },
  serializeData: function () {
    var contextPath = myApp.ent.config.get('contextPath')
    var content = {
      contextPath: contextPath,
      JspPublicCodes_JSP_ITEM_ALERTS_EDIT_TOOLTIP: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_EDIT_TOOLTIP'),
      JspPublicCodes_JSP_ITEM_ALERTS_DELETE_TOOLTIP: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_DELETE_TOOLTIP'),
      JspPublicCodes_JSP_ITEM_ALERTS_VIEW: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_VIEW'),
      frequency: this.getFrequency()
    }
    content = _.extend(content, this.model.attributes)
    content = _.extend(content, this.getStateAttributes())
    return content
  },
  getFrequency: function () {
    var frequency = []

    if (this.model.get('runOnSunday')) frequency.push(myApp.reqres.request('i16:getString', 'JSTextCodes_SUN'))
    if (this.model.get('runOnMonday')) frequency.push(myApp.reqres.request('i16:getString', 'JSTextCodes_MON'))
    if (this.model.get('runOnTuesday')) frequency.push(myApp.reqres.request('i16:getString', 'JSTextCodes_TUE'))
    if (this.model.get('runOnWednesday')) frequency.push(myApp.reqres.request('i16:getString', 'JSTextCodes_WED'))
    if (this.model.get('runOnThursday')) frequency.push(myApp.reqres.request('i16:getString', 'JSTextCodes_THU'))
    if (this.model.get('runOnFriday')) frequency.push(myApp.reqres.request('i16:getString', 'JSTextCodes_FRI'))
    if (this.model.get('runOnSaturday')) frequency.push(myApp.reqres.request('i16:getString', 'JSTextCodes_SAT'))

    return frequency.join(', ')
  },
  getStateAttributes: function () {
    var pauseFaClass
    var pauseTooltip
    var statusText

    if (this.model.get('status') === 'LIVE') {
      pauseTooltip = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_PAUSE_TOOLTIP')
      pauseFaClass = 'fa-pause'
      statusText = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_STATUS_LIVE')
    } else {
      pauseTooltip = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_UNPAUSE_TOOLTIP')
      pauseFaClass = 'fa-play'
      statusText = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_STATUS_PAUSED')
    }

    return {
      pauseFaClass: pauseFaClass,
      pauseTooltip: pauseTooltip,
      statusText: statusText
    }
  }
})
