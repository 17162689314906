PageApp.Controllers.AuctioneerRouter = function (config) {
  function initCommands () {
  }
  function initEvents () {
    myApp.vent.on('auctioneer:display', function (homePageModel) {
      myApp.mainRegion.show(new PageApp.Views.AuctionsForAuctioneerView({ model: homePageModel.upcomingModel }))
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
      initCommands()
    }
  }
}
