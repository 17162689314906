PageApp.Views.ConfirmPauseView = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.confirmOkCancel,
  initialize: function (options) {
    this.model = options.model
    this.itemAlert = this.model.get(options.id)
  },
  serializeData: function () {
    return {
      confirmMsg: this.getConfirmMsg(),
      okButtonText: this.getOkButtonText(),
      JSTextCodes_CANCEL: myApp.reqres.request('i16:getString', 'JSTextCodes_CANCEL')
    }
  },
  events: {
    'click .x-ok': 'togglePause'
  },
  getConfirmMsg: function () {
    if (this.itemAlert.get('status') === 'LIVE') { return myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_CONFIRM_PAUSE') } else {
      return myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_CONFIRM_UNPAUSE')
    }
  },
  getOkButtonText: function () {
    if (this.itemAlert.get('status') === 'LIVE') { return myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_PAUSE') } else {
      return myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_UNPAUSE')
    }
  },
  togglePause: function (e) {
    if (this.itemAlert.get('status') === 'LIVE') { this.itemAlert.set('status', 'PAUSED') } else {
      this.itemAlert.set('status', 'LIVE')
    }

    this.itemAlert.save({}, { success: _.bind(this.handleSuccess, this), error: _.bind(this.handleError, this) })
    return true
  },
  handleError: function (model, xhr, options) {
    myApp.vent.trigger('error:notifyServerError', _.pick(xhr, 'responseJSON', 'status', 'statusText'))
  },
  handleSuccess: function (model) {
    var message = ' ' + model.get('name')

    if (this.itemAlert.get('status') === 'PAUSED') { message = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_PAUSED_ALERT') + message } else {
      message = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_UNPAUSED_ALERT') + message
    }
    myApp.vent.trigger('ui:notification', { text: message, level: 'success' })
  }
})
