PageApp.Views.ItemAlertsActionsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemAlertsActions,
  serializeData: function () {
    var contextPath = myApp.ent.config.get('contextPath')
    var content = {
      contextPath: contextPath,
      JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT')
    }
    return content
  }
})
