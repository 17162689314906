PageApp.Views.ItemAlertsHeaderView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemAlertsHeader,
  serializeData: function () {
    var content = {
      JspPublicCodes_JSP_ITEM_ALERTS_TITLE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_TITLE'),
      JspPublicCodes_JSP_ITEM_ALERTS_DESC: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM_ALERTS_DESC')
    }
    return content
  }
})
