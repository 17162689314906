PageApp.Views.AuctionsForAuctioneerView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.auctionsForAuctioneer,
  className: function () { return myApp.ent.config.get('containerClass') },
  serializeData: function () {
    var showAuctionLogos = false
    try {
      showAuctionLogos = myApp.ent.webApp.attributes.showAuctionLogosOnLandingPages
    } catch (err) {
      showAuctionLogos = false
    }
    var auctioneer = this.model.liveAuctioneers[0]
    var auctions = this.model.upcomingAuctions
    var upcomingFeaturedAuctions = this.model.upcomingFeaturedAuctions

    var aboutLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ABOUT')
    var telephoneLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_SHORT_TELEPHONE')
    var emailLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_EMAIL')
    var websiteLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_WEBSITE')
    var auctioneerLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTIONEER')
    var awaitingImageMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AWAITING_IMAGE')

    var m1 = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FEATURED_AUCTIONS')
    var m11 = '' // myApp.reqres.request('i16:getString','JspPublicCodes_JSP_AUCTIONS');
    var m2 = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HOMEPAGE_EVENT_AUCTIONS')
    var m3 = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HOMEPAGE_WEBCAST_AUCTIONS')

    var itemMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM')
    var itemsMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEMS')
    for (var key in auctions) {
      auctions[key].message = (auctions[key].itemCount > 1) ? itemsMessage : itemMessage
    }
    for (var key2 in upcomingFeaturedAuctions) {
      upcomingFeaturedAuctions[key2].message = (upcomingFeaturedAuctions[key2].itemCount > 1) ? itemsMessage : itemMessage
    }

    var unfeaturedMessage = ''
    if (this.model.countEvent > 0 || this.model.countWebcast > 0) {
      unfeaturedMessage = m11
      if (this.model.countEvent > 0) {
        unfeaturedMessage += ' ' + this.model.countEvent + ' ' + m2
      }
      if (this.model.countWebcast > 0) {
        unfeaturedMessage += ' ' + this.model.countWebcast + ' ' + m3
      }
    }
    var featuredMessage = ''
    if (this.model.countFeaturedEvent > 0 || this.model.countFeaturedWebcast > 0) {
      featuredMessage = m1
      if (this.model.countFeaturedEvent > 0) {
        featuredMessage += ' ' + this.model.countFeaturedEvent + ' ' + m2
      }
      if (this.model.countFeaturedWebcast > 0) {
        featuredMessage += ' ' + this.model.countFeaturedWebcast + ' ' + m3
      }
    }

    var dims = myApp.ent.layoutHacks.getDimensionsHomeCal()
    dims.operation = myApp.ent.config.get('auctionImageTransformOperation')
    var logoDims = myApp.ent.layoutHacks.getDimensionsHomeCal()
    logoDims.operation = 'pad'

    return {
      logoDims: logoDims,
      dims: dims,
      contextPath: myApp.ent.config.get('contextPath'),
      featuredMessage: featuredMessage,
      unfeaturedMessage: unfeaturedMessage,
      auctioneer: auctioneer,
      upcomingAuctions: this.model.upcomingAuctions,
      upcomingFeaturedAuctions: this.model.upcomingFeaturedAuctions,
      countEvent: this.model.countEvent,
      countWebcast: this.model.countWebcast,
      countFeaturedEvent: this.model.countFeaturedEvent,
      countFeaturedWebcast: this.model.countFeaturedWebcast,
      aboutLabel: aboutLabel,
      telephoneLabel: telephoneLabel,
      emailLabel: emailLabel,
      websiteLabel: websiteLabel,
      auctioneerLabel: auctioneerLabel,
      awaitingImageMessage: awaitingImageMessage,
      showAuctionLogos: showAuctionLogos

    }
  },
  onShow: function () {
    this.addRegistrationLinks(this.model.upcomingFeaturedAuctions)
    this.addRegistrationLinks(this.model.upcomingAuctions)
  },
  addRegistrationLinks: function (auctions) {
    var contextPath = myApp.ent.config.get('contextPath')
    for (var key in auctions) {
      var auction = auctions[key]
      var regState = this.model.registeredFor[auction.auctionId]
      if (auction.catalogue) {
        var link = '<span id="catalogueAuction' + auction.auctionId + '">' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATALOGUE_AUCTION') + '</span>'
        this.$el.find('#registration_' + auction.auctionId).html(link)
      } else {
        if (!regState) {
          link = '<a class="register-for-auction" id="registerForAuctionFromAuction' + auction.auctionId + '" href="' + contextPath + '/registerForAuction/' + auction.auctionId + '">' + myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_NOT_REGISTERED') + '</a>'
          this.$el.find('#registration_' + auction.auctionId).html(link)
        } else {
          link = '<span class="register-for-auction" id="auctionstatus' + auction.auctionId + '">' + myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_' + regState) + '</span>'
          this.$el.find('#registration_' + auction.auctionId).html(link)
        }
      }
    }
  }
})
