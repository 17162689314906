PageApp.Ent.BillingAddress = Backbone.Model.extend({
  defaults: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    countyOrState: '',
    postcode: '',
    country: ''
  },

  validate: function () {
    var errors = new PageApp.Ent.Errors()
    this.validateAddressLine1(errors)
    this.validateCity(errors)
    this.validatePostcode(errors)
    this.validateCountry(errors)
    if (errors.length === 0) {
      return false
    } else {
      return errors
    }
  },
  validateAddressLine1: function (errors) {
    if (myApp.utils.validation.stringIsEmpty(this.get('addressLine1'))) {
      errors.add({
        field: 'billingAddressLine1',
        code: 'JSTextCodes_FIELD_NOT_COMPLETE'
      })
    }
  },
  validateCity: function (errors) {
    if (myApp.utils.validation.stringIsEmpty(this.get('city'))) {
      errors.add({
        field: 'billingAddressCity',
        code: 'JSTextCodes_FIELD_NOT_COMPLETE'
      })
    }
  },
  validatePostcode: function (errors) {
    if (myApp.utils.validation.stringIsEmpty(this.get('postcode'))) {
      errors.add({
        field: 'billingAddressPostcode',
        code: 'JSTextCodes_FIELD_NOT_COMPLETE'
      })
    }
  },
  validateCountry: function (errors) {
    if (!myApp.utils.validation.existsAndPositiveLength(this.get('country'))) {
      errors.add({
        field: 'billingAddressCountry',
        code: 'JSTextCodes_SELECT_FROM_MENU'
      })
    }
  }

})
