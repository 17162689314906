PageApp.Views.ItemBreadcrumbView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemBreadcrumb,
  serializeData: function () {
    var content = {
      JspPublicCodes_JSP_TRANSLATION_DISCLAIMER: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_TRANSLATION_DISCLAIMER'),
      JspPublicCodes_JSP_MY_SALES_BACK_TO_MY_SALES: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MY_SALES_BACK_TO_MY_SALES'),
      JspPublicCodes_JSP_VIEW_MARKETPLACE_FOR: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_VIEW_MARKETPLACE_FOR'),
      JspPublicCodes_JSP_VIEW_AUCTION: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_VIEW_AUCTION'),
      JspPublicCodes_JSP_PREVIOUS_ITEM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PREVIOUS_ITEM'),
      JspPublicCodes_JSP_NEXT_ITEM: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_NEXT_ITEM')
    }
    _.extend(content, this.model.attributes)
    return content
  }
})
